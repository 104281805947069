import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Libraries
import {
  size as _size,
  concat as _concat,
  find as _find,
  each as _each,
  replace as _replace
} from 'lodash'

// Language
import { getLanguage, setLanguage } from 'services/language'

// CSS
import './LanguageSwitch.scss'

const LanguageSwitch = () => {
  const {
    pages,
    posts,
    site: { pathPrefix },
  } = useStaticQuery(graphql`
    {
      site {
        pathPrefix
      }
      pages: allWordpressPage {
        edges {
          node {
            path
            locale: wpml_current_locale
            translations: wpml_translations {
              locale
              path: href
            }
          }
        }
      }
      posts: allWordpressPost {
        edges {
          node {
            path
            locale: wpml_current_locale
            translations: wpml_translations {
              locale
              path: href
            }
          }
        }
      }
    }
  `)

  const language = getLanguage()

  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  if (pathPrefix) {
    pathname = _replace(pathname, pathPrefix, '')
  }

  const allPages = _concat(posts.edges, pages.edges)
  let toPage = null

  _each(allPages, ({ node }) => {
    if (node.path === pathname) {
      toPage = node.translations[0]
    }

    if (_size(_find(node.translations, ({ path }) => path === pathname)) > 0) {
      toPage = node
    }
  })

  if (!toPage) {
    toPage = language === 'nl_NL' ? { path: '/en/home/' } : { path: '/' }
  }

  const switchLanguage = () => {
    if(toPage.path !== '/' && toPage.path !== '/home/') {
      document.getElementById('tidio-chat').style.display = 'none'
    } else {
      document.getElementById('tidio-chat').style.display = 'block'
    }

    setLanguage(toPage)
  }

  return (
    <button
      type="button"
      className="language-switch"
      onClick={() => {
        switchLanguage(toPage)
      }}
    >
      <div
        className={`language-switch-option${
          language === 'nl_NL' ? ` font-weight-l` : ``
        }`}
      >
        NL
      </div>
      <div className="language-switch-toggle">
        <div
          className={`language-switch-toggle-circle ${
            language == 'nl_NL'
              ? `language-switch-toggle-circle-left`
              : `language-switch-toggle-circle-right`
          }`}
        />
      </div>
      <div
        className={`language-switch-option${
          language == 'en_US' ? ` font-weight-l` : ``
        }`}
      >
        EN
      </div>
    </button>
  )
}

export default LanguageSwitch
