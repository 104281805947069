import React from 'react'
import { Link } from 'gatsby'

const isPartiallyActive = data => {
  let {isPartiallyCurrent} = data

  // if 'to' location is '/' and current page is home than set partially current
  if(data.location.pathname === '/' && data.href === '/home/')
    isPartiallyCurrent = true

  const hashSplit = data.href.split('#')

  // if 'to' has hash than custom check partially current
  if(hashSplit[1]) {
    hashSplit[0] = hashSplit[0].replace('/#', '')
    hashSplit[0] = hashSplit[0].replace('#', '')

    if(hashSplit[0] === data.location.pathname) {
      isPartiallyCurrent = true
    }
  }

  return isPartiallyCurrent ? { 'aria-current': `page` } : null
}

const customClick = path => {
  if(path !== '/' && path !== '/home/') {
    document.getElementById('tidio-chat').style.display = 'none'
  } else {
    document.getElementById('tidio-chat').style.display = 'block'
  }
}

const CustomLink = ({ to, className, children }) => (
  <Link getProps={isPartiallyActive} to={to} onClick={() => { customClick(to) }} className={className}>
    {children}
  </Link>
)

export default CustomLink
