import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Libraries
import { map as _map } from 'lodash'

// CSS
import './Socials.scss'

const Socials = () => {
  const { socials } = useStaticQuery(graphql`
    {
      socials: allWordpressWpSocials {
        edges {
          node {
            id: wordpress_id
            title
            acf {
              url
              icon {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="socials">
      {_map(socials.edges, ({ node: { id, title, acf: { url, icon } } }) => (
        <a
          key={id}
          className="socials-platform ignore-element"
          href={url}
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            alt={title}
            className="socials-platform-icon"
            src={icon.localFile.publicURL}
          />
          {title}
        </a>
      ))}
    </div>
  )
}

export default Socials
